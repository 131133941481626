var marathonApp = angular.module('marathon', []);

//Disabled debug info to provide significant performance boost
//If you need debug info, open your dev tools and run this in the console window: angular.reloadWithDebugInfo();
marathonApp.config(['$compileProvider', '$locationProvider', function ($compileProvider, $locationProvider) {
    $compileProvider.debugInfoEnabled(false);
    $locationProvider.hashPrefix('');
}]).service('UrlService', ['$http', '$q', function ($http, $q) {

    this.GetData = function (url, id) {
        var searchUrl = url + "?id=" + id;
        var deferred = $q.defer();
        $http.get(searchUrl).then(function (response) {
            deferred.resolve(response.data);
        });
        return deferred.promise;
    }
}]);